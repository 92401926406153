@font-face {
    font-family: AeonikBold;
    src: url("fonts/AeonikTRIAL-Bold.otf") format("opentype");
}

#test {
    background-color: aqua;
    color: red;
    height: 50px;
    width: 50px;
}

body{
    margin: 0;
    color: #F0F0F0;
    font-family: Arial;
}

#background {
    z-index: -1;
    background-image: url("images/background_empty9.png");
    height: 734px;
    width: 1391px;
    position: absolute;
}

#content {
    z-index: 1;
}

#canvas-element {
    position: absolute;
    top: 246px;
    left: 332.5px;
}

#canvas-containers {
    position: relative;

}

canvas {
    position: absolute;
    top: 0;
    left: 0;

}

#playButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    /* top: 378px; */
    top: 546px;
    /* left: 302px; */
    left: 331px;
}

#skipBackward {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 546px;
    left: 349px;
}

#skipForward {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 546px;
    left: 371px;
}

#commentButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 206px;
    left: 949px;
}

#duration {
    position: absolute;
    top: 243px;
    left: 910px;
    color: #707070;
    font-size: xx-small;
    font-family: Arial;
}

#comment-input {
    background-color: #171719;
    color: #D4DFE1;
    border: none;
    width: 290px;
    height: 26px;
    font-size: x-small;
    margin-top: 5px;
    margin-left: 12px;
}

#comment-div {
    position: absolute;
    top: 519px;
    left: 1003px;
    border-radius: 5px;
    background-color: #171719;
    border: none;
    width: 341px;
    height: 38px;
}

#comment-input:focus {
    outline: none;
}

#add-comment-button {
    cursor: pointer;
    position: absolute;
    top: 533px;
    left: 1290px;
}

#comment-list {
    position: absolute;
    top: 205px;
    left: 1003px;
    height: 293px;
    overflow-y: scroll;
    border: none;
}

#comment {
    width: 341px;
    border-radius: 5px;
    background-color: #171719;
    color: #D4DFE1;
    font-size: x-small;
    font-family: Arial;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#comment-title {
    margin-left: 9px;
    color: #D4DFE1;
    font-size: x-small;
    font-family: Arial;
    display: flex;
    flex-direction: row;
}

#avatar {
    width: 21px;
    height: 21px;
    padding-top: 0;
}

#comment-title-text {
    font-family: Arial;
    font-weight: bold;
    margin-left: 7px;
    margin-top: 4px;
    color: #D4DFE1;
}

#comment-content {
    margin-top: 5px;
    margin-left: 38px;
    font-size: 12px;
    margin-right: 15px;
}

#comment-time {
    font-weight: bold;
    /* color: aqua; */
}

#comment-footer {
    margin-top: 4px;
    margin-left: 36px;
    color: #707070;
    font-weight: bold;

}

.buttonNoStyle {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	/* cursor: pointer; */
	outline: inherit;
}

#volumeButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 39.5px;
    left: 2px;
}

#muteButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 38.5px;
    left: 1px;
}

#volumeSlider {
    position: absolute;
    top: 13.5px;
    left: -13.5px;
    height: 3px;
    width: 45px;
    transform: rotate(-90deg);
    -webkit-appearance: none;
    background: #2E2E2F;  
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
}

#volumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #FFB500;
    box-shadow: -401px 0 0 400px #FFB500;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
}

#volume-container {
    position: relative;
    margin-top: 488px;
    margin-left: 391px;
    height: 60px;
    width: 20px;
}

#playback-speed-container {
    position: relative;
    margin-top: -97px;
    margin-left: 413px;
    height: 90px;
    width: 30px;
}

#playbackOptions {
    background-color: #0A0A0A;
    border: 1.5px solid #333333;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 74px;
    width: 27px;
    border-radius: 5px;
}

.playbackButton {
    width: 100%;
    padding: 0;
    margin-bottom: 1px;
    font-size: 7px;
    cursor: pointer;
    font-family: Arial;
    font-weight: bold;
    text-align: center;
    background: none;
    border: none;
    color: #F0F0F0;
}

.playbackButton:hover {
    color: #FFB500;
}

#selectedPlaybackButton {
    position: absolute;
    top: 80px;
    width: 100%;
    padding: 0;
    font-size: 9px;
    cursor: pointer;
    font-family: Arial;
    background: none;
    border: none;
    color: #F0F0F0;
    font-weight: bold;
    text-align: center;
}

#zoomSlider {
    position: absolute;
    top: 550.5px;
    left: 540px;
    height: 3px;
    width: 47px;
    -webkit-appearance: none;
    background: #2E2E2F;  
    border-radius: 10px;
    cursor: pointer;

}

#zoomSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4.5px;
    height: 4.5px;
    border-radius: 50%;
    background: #FFB500;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;

}

#zoomInButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 546px;
    left: 594px;
}

#zoomOutButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 546px;
    left: 522px;
}

#zoomFollower {
    position: relative;
    -webkit-appearance: none;
    --dummy: 10px;
    width:148px;
    background: #0A0A0A;
    height: 10px;
    margin: 0px;
}

#zoomFollowerSection {
    border-left: 1.5px solid #707070;
    border-right: 1.5px solid #707070;
    height: 14px;
    width: 148px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 547px;
    left: 633px;
}

#zoomFollower::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: var(--dummy);
    height: 10px;
    background: #D3DEE0;
    opacity: 0.3;
    cursor: pointer;

}

#zoomFollowerPlayhead {
    position: absolute;
    left: 0px;
    width: 2px;
    height: 14px;
    background: #D3DEE0;
}

#commentMediaControls {
    position:absolute;
    width: 65px;
    height: 22px;
    border-radius: 4px;
    border: 1.5px solid #333333;
    background-color: #0A0A0A;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 262px;
}

#setToStartButton{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 6.5px;
    margin-top: 5.5px;
}

#commentPlayButton{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.5px;
    margin-top: 7px;
}

#loopButton{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 6.5px;
    margin-top: 4.5px;
}